import { forwardRef, memo } from "react";
import { PropTypes } from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import * as reviewsStyles from "./reviews/styles.module.scss";

const Reviews = forwardRef(({ productId }, ref) => (
  <div id="reviews" className={reviewsStyles.reviews} ref={ref}>
    <div data-bv-show="reviews" data-bv-product-id={productId} />
    <div data-bv-show="questions" data-bv-product-id={productId} />
  </div>
));

Reviews.propTypes = {
  productId: PropTypes.string,
};

export default withALErrorBoundary({
  name: "Reviews",
  priority: "P3",
})(memo(Reviews));
