// extracted by mini-css-extract-plugin
export var awesome = "styles-module--awesome--b2884";
export var bold = "styles-module--bold--53da3";
export var center = "styles-module--center--10ed3";
export var h1 = "styles-module--h1--2e222";
export var h1Bold = "styles-module--h1--bold--0d428";
export var h1Uc = "styles-module--h1--uc--875e5";
export var h2 = "styles-module--h2--3274c";
export var h2Bold = "styles-module--h2--bold--bbafc";
export var h2Uc = "styles-module--h2--uc--149e4";
export var h3 = "styles-module--h3--e3f9a";
export var h3Bold = "styles-module--h3--bold--63d90";
export var h3Uc = "styles-module--h3--uc--b77b7";
export var h4 = "styles-module--h4--c1297";
export var h4Bold = "styles-module--h4--bold--10425";
export var h4Uc = "styles-module--h4--uc--2d3b4";
export var linkUnderline = "styles-module--link--underline--59e1b";
export var microtext = "styles-module--microtext--07e7d";
export var microtextBold = "styles-module--microtext--bold--300d8";
export var microtextUc = "styles-module--microtext--uc--28f89";
export var reviews = "styles-module--reviews--16a59";
export var strikethrough = "styles-module--strikethrough--a9ad1";
export var strikethroughLg = "styles-module--strikethrough--lg--2825a";
export var strikethroughSm = "styles-module--strikethrough--sm--288e6";
export var subtext = "styles-module--subtext--b2373";
export var subtextBold = "styles-module--subtext--bold--6c683";
export var subtextUc = "styles-module--subtext--uc--f722e";
export var text = "styles-module--text--5fa35";
export var textBold = "styles-module--text--bold--91aa2";
export var textUc = "styles-module--text--uc--18e66";
export var titleL = "styles-module--title-l--e7595";
export var titleM = "styles-module--title-m--944f9";
export var titleS = "styles-module--title-s--f1cf9";
export var titleXl = "styles-module--title-xl--333a0";
export var titleXs = "styles-module--title-xs--13b12";
export var titleXxs = "styles-module--title-xxs--0ccfa";
export var uc = "styles-module--uc--fa662";
export var underline = "styles-module--underline--eea57";