import { useState } from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import SwiperCore, { Navigation, Pagination, A11y, Thumbs, FreeMode } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import { resizeShopifyImage } from "../context/helpers";
import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";
import useResponsiveWithHydrationFix from "../hooks/useResponsiveWithHydrationFix";

import { ALVideo } from "./ALComponents";
import MobileGalleryPopup from "./ProductPage/gallery/MobileGalleryPopup";
import IconArrowCircleSm from "./icons/svgs/arrow-circle-sm.svg";

import * as Styles from "./galleryMini/styles.module.scss";

SwiperCore.use([Navigation, Pagination, A11y, FreeMode, Thumbs]);

function GalleryMini({ images = [], videoId }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(images?.length > 0 ? images[0] : null);

  const openPopup = (img) => {
    setActiveImage(img);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const cssLazyLoadMobile = {
    display: "flex",
    alignItems: "center",
    width: "89.3vw",
    height: "89.3vw",
    position: "relative",
  };

  const responsiveComponent = useResponsiveWithHydrationFix(
    <div className={Styles.galleryMobile}>
      {images.map((image, i) => (
        <LazyLoad style={cssLazyLoadMobile} once key={`lp_pdp_${i}_mobile`}>
          <img
            className={Styles.galleryMiniImage}
            key={`lp_pdp_${i}_mobile_img`}
            src={resizeShopifyImage(image.url, "x900")}
            alt={image.altText}
            onClick={() => openPopup(image)}
          />
        </LazyLoad>
      ))}
      {videoId && videoId !== "null" && (
        <ALVideo
          videoId={videoId}
          customClasses={{
            video_container: "video_container",
            video: Styles.video,
          }}
        />
      )}
    </div>,
    <div className={Styles.galleryDesktop}>
      <div className={Styles.navigationButtons}>
        <button className="gallery-mini-prev-button" type="button" aria-label="previous slide">
          <IconArrowCircleSm width="31" stroke="#2D2927" />
        </button>
        <button className="gallery-mini-next-button" type="button" aria-label="next slide">
          <IconArrowCircleSm width="31" stroke="#2D2927" />
        </button>
      </div>
      <Swiper
        navigation={{
          nextEl: ".gallery-mini-next-button",
          prevEl: ".gallery-mini-prev-button",
        }}
        slidesPerView={2}
        slidesPerGroup={2}
        spaceBetween={6}
      >
        {images.map((image) => (
          <SwiperSlide key={image.url}>
            <img src={resizeShopifyImage(image.url, "x900")} alt={image.altText} />
          </SwiperSlide>
        ))}
        {videoId && videoId !== "null" && (
          <SwiperSlide key={videoId}>
            <ALVideo videoId={videoId} />
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );

  if (!images) {
    return "";
  }

  return (
    <div className={Styles.gallery}>
      {responsiveComponent}
      {isPopupOpen && (
        <MobileGalleryPopup
          images={images}
          activeImage={activeImage}
          setActiveImage={setActiveImage}
          closePopup={closePopup}
        />
      )}
    </div>
  );
}

GalleryMini.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      altText: PropTypes.string,
    })
  ),
  videoId: PropTypes.string,
};

export default withALErrorBoundary({
  name: "GalleryMini",
  priority: "P3",
})(GalleryMini);
