import { PropTypes } from "prop-types";

import { Popup } from "./Popup";
import Reviews from "./ProductPage/Reviews";
import IconCloseRound from "./icons/svgs/close-round.svg";

import "./ProductPage/reviews/review_popup.scss";

function ReviewsPopup({ open, onClose, productId = "" }) {
  return (
    <Popup open={open} onClose={onClose} className="reviews_popup">
      <button className="reviews_popup_close" onClick={onClose} type="button">
        <IconCloseRound fill="none" stroke="#2D2927" width="30px" />
      </button>
      <Reviews ref={null} productId={productId} />
    </Popup>
  );
}

ReviewsPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  productId: PropTypes.string,
};

export default ReviewsPopup;
